import { use } from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { BarChart, PieChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { API_URL } from "../../_plugins/axios";
import Multiselect from "vue-multiselect";
// import debounce from "lodash/debounce";

use([
  SVGRenderer,
  PieChart,
  BarChart,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "Reports",
  components: {
    VChart,
    Multiselect,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      reports: [],
      users: [],
      selectedUsers: [],
      sectors: [],
      selectedSectors: [],
      categories: [],
      subcategories: [],
      selectedCategories: [],
      selectedSubcategories: [],
      selectedAvailable: [],
      loading: false,
      excel_options: {
        users: [],
        sectors: [],
        categories: [],
        subcategories: [],
        equipment: [],
        year: `${new Date().getFullYear()}`,
      },
      excel_options2: {
        categories: [],
        subcategories: [],
        available: null,
        year: `${new Date().getFullYear()}`,
      },
      equipment_category_categories: [],
      option: {
        title: {
          text: "Opreme po kategoriji",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          // formatter: "{a} <br/>{b} : {c} ({d}%)",
          formatter: (params) => {
            return `${params.seriesName}<br />
              ${params.name}: ${params.data.value} (${(
              (params.data.value / this.reports.total_equipment) *
              100
            ).toFixed(1)}%)<br />`;
          },
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: [],
        },
        series: [
          {
            name: "Traffic Sources",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },

      option2: {
        title: {
          text: "Potrošnja po mjesecima",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          // formatter: "{a} <br/>{b} : {c} ({d}%)",
          formatter: (params) => {
            return `${params.seriesName}<br />
              ${params.name}: ${params.data.value} (${(
              (params.data.value / this.reports.sum) *
              100
            ).toFixed(1)}%)<br />`;
          },
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: [],
        },
        series: [
          {
            name: "Traffic Sources",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    };
  },
  methods: {
    changeTabs() {
      this.selectedSubcategories = [];
      this.selectedCategories = [];
      this.selectedAvailable = [];
      this.selectedUsers = [];

      this.excel_options2.subcategories = [];
      this.excel_options2.categories = [];
      this.excel_options2.available = [];
      this.excel_options2.year = `${new Date().getFullYear()}`;
    },
    removedCategory(value) {
      console.log(value.id);
      this.selectedSubcategories = this.selectedSubcategories.filter((item) => {
        console.log(item.category_id !== value.id);
        return item.category_id !== value.id;
      });
    },
    changeInput() {
      this.excel_options.users = [];
      this.selectedUsers.forEach((user) => {
        this.excel_options.users.push(user.id);
      });

      this.excel_options.sectors = [];
      this.selectedSectors.forEach((sector) => {
        this.excel_options.sectors.push(sector.id);
      });

      this.excel_options.categories = [];
      this.selectedCategories.forEach((category) => {
        this.excel_options.categories.push(category.id);
      });

      this.excel_options.subcategories = [];
      this.selectedSubcategories.forEach((category) => {
        this.excel_options.subcategories.push(category.id);
      });
      this.excel_options2.available = null;
      this.excel_options2.available = this.selectedAvailable.id;
    },

    changeInput2(subcat = false) {
      this.excel_options2.categories = [];
      // this.subcategories = [];
      // this.selectedSubcategories = [];
      this.selectedCategories.forEach((category) => {
        this.excel_options2.categories.push(category.id);
      });

      if (subcat) {
        let subcategories = [];
        this.subcategories = [];
        this.categories.filter((item) => {
          this.excel_options2.categories.forEach((cat) => {
            if (item.id === cat) subcategories.push(item.subcategories);
          });
        }),
          (subcategories = subcategories.forEach((item) => {
            item.forEach((subcat) => {
              this.subcategories.push(subcat);
            });
          }));
      }
      this.excel_options2.subcategories = [];
      this.selectedSubcategories.forEach((category) => {
        this.excel_options2.subcategories.push(category.id);
      });
      this.excel_options2.available = null;
      this.excel_options2.available = this.selectedAvailable.id;
    },
    getAllUsers() {
      API_URL.get("users/all").then((response) => {
        this.users = response.data;
      });
    },
    getAllSectors() {
      API_URL.get("sector").then((response) => {
        this.sectors = response.data.data;
      });
    },
    getAllCategories() {
      API_URL.get("category").then((response) => {
        this.categories = response.data.data;
      });
    },
    getAllSubcategories() {
      API_URL.get("subcategory").then((response) => {
        this.subcategories = response.data;
      });
    },
    getExcel() {
      this.loading = true;
      let vm = this;
      // this.$toast.info("Generišem izvještaj...");
      API_URL.get("/export-equipment-documents", {
        responseType: "blob",
        params: vm.excel_options,
      }).then((response) => {
        this.$toast.success("Izvještaj generisan");
        let fileLink = document.createElement("a");

        fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
        fileLink.setAttribute(
          "download",
          `Kartoni opreme - ${this.$moment().format("D-M-Y")}.xlsx`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
    getExcelEquipment() {
      this.loading = true;
      let vm = this;
      // this.$toast.info("Generišem izvještaj...");
      API_URL.get("/export-equipment", {
        responseType: "blob",
        params: vm.excel_options2,
      })
        .then((response) => {
          this.$toast.success("Izvještaj generisan");
          let fileLink = document.createElement("a");

          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute(
            "download",
            `Oprema - ${this.$moment().format("D-M-Y")}.xlsx`
          );
          document.body.appendChild(fileLink);

          fileLink.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getReports() {
      this.option.legend.data = [];
      this.option.series[0].data = [];
      this.option2.series[0].data = [];
      let vm = this;
      API_URL.get("reports", {
        params: vm.excel_options2,
      }).then((res) => {
        this.reports = res.data;

        this.reports.months.forEach((item) => {
          this.option2.legend.data.push(item.name);
          this.option2.series[0].data.push(item);
        });

        this.reports.equipment_per_category.forEach((item) => {
          this.option.legend.data.push(item.name);
          this.option.series[0].data.push(item);
        });
        // this.options.legend.data
      });
    },
  },
  // watch: {
  //   excel_options2: {
  //     deep: true,
  //     immediate: false,
  //     // eslint-disable-next-line no-unused-vars
  //     handler: debounce(function (value) {
  //       this.getReports();
  //     }, 250),
  //   },
  // },
  mounted() {
    this.getReports();
    this.getAllUsers();
    this.getAllSectors();
    this.getAllCategories();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Izvjestaji",
    });
  },
};
